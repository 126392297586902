export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        id: 1,
        _name: "CSidebarNavDropdown",
        name: "Content2",
        icon: "cil-loop-circular",
        key: "Content2",

        items: [
          {
            id: 2,
            name: "Product Banner",
            key: "test",
            to: "/productbanner",
          },
          {
            id: 3,
            name: "Product Mobile",
            key: "product_mobile",
            to: "/productmobile",
          },
          {
            id: 4,
            _name: "CSidebarNavItem",
            name: "Sales Channel",
            key: "sales_channel",
            to: "/saleschannel",
          },
          {
            id: 5,
            _name: "CSidebarNavItem",
            name: "Notification",
            to: "/notification",
            // icon: "cil-bell",
            key: "notification",
          },
          {
            id: 6,
            _name: "CSidebarNavItem",
            name: "Video",
            to: "/video",
            // icon: "cil-video",
            key: "video",
          },
        ],
      },
      {
        id: 7,
        _name: "CSidebarNavDropdown",
        name: "Content",
        key: "banner",

        icon: "cil-newspaper",
        items: [
          {
            id: 8,
            name: "Banner",
            key: "banner",
            to: "/banner",
          },
          {
            id: 9,
            _name: "CSidebarNavItem",
            name: "News/Promotion News",
            to: "/promotionnews",
            // icon: "cil-newspaper",
            key: "news_promotion_news",
            is_sub_menu: 1,
          },
          {
            id: 10,
            _name: "CSidebarNavItem",
            name: "Category",
            is_sub_menu: 1,
            to: "/category",
          },
        ],
      },
      {
        id: 11,
        _name: "CSidebarNavItem",
        name: "Customer",
        to: "/report/customer",
        icon: "cil-people",
        key: "customer",
      },

      {
        id: 12,
        _name: "CSidebarNavItem",
        name: "Transaction",
        to: "/report/transaction",
        icon: "cil-credit-card",
        key: "transaction",
      },
      {
        id: 13,
        _name: "CSidebarNavItem",
        name: "Product",
        to: "/product",
        icon: "cil-list",
        key: "product",
        is_sub_menu: 1,
      },

      {
        id: 14,
        _name: "CSidebarNavDropdown",
        name: "Privilege",
        icon: "cil-gift",
        key: "privilege",

        _children: [
          {
            id: 15,
            _name: "CSidebarNavItem",
            name: "Privilege",
            to: "/privilegecode",
            key: "privilege_code",
          },
          {
            id: 16,
            _name: "CSidebarNavItem",
            name: "Beauty Passport",
            to: "/stampcard",
            key: "beauty_passport",
          },
          {
            id: 17,
            _name: "CSidebarNavItem",
            name: "Coupon",
            to: "/coupon",
            key: "coupon",
          },
        ],
      },

      {
        id: 18,
        _name: "CSidebarNavItem",
        name: "Campaign Forms",
        to: "/form",
        icon: "cil-columns",
        key: "campaign_forms",
      },
      {
        id: 19,
        _name: "CSidebarNavDropdown",
        name: "Marketing Automation",
        icon: "cil-loop-circular",
        key: "marketing_automation",

        _children: [
          {
            id: 20,
            _name: "CSidebarNavItem",
            name: "Automation",
            to: "/automation",
            key: "automation",
          },
          {
            id: 21,
            _name: "CSidebarNavItem",
            name: "Send Message",
            to: "/automation/sendmessage",
            key: "send_message",
          },
        ],
      },

      {
        id: 22,
        _name: "CSidebarNavItem",
        name: "Extra Point",
        to: "/extrapoint",
        icon: "cil-layers",
        key: "extra_point",
      },
      {
        id: 23,
        _name: "CSidebarNavItem",
        name: "Redeem",
        to: "/redeem",
        icon: "cil-puzzle",
        key: "redeem",
      },

      {
        id: 24,
        _name: "CSidebarNavItem",
        name: "Right Request",
        to: "/allrightrequest",
        icon: "cil-layers",
        key: "all_right_request",
      },

      {
        id: 25,
        _name: "CSidebarNavItem",
        name: "Activity Log",
        to: "/activitylog",
        icon: "cil-list",
        key: "activity_log",
      },

      {
        id: 26,
        _name: "CSidebarNavDropdown",
        name: "Configuration",
        icon: "cil-settings",
        key: "configuration",
        _children: [
          {
            id: 27,
            _name: "CSidebarNavItem",
            name: "Member Tier",
            to: "/membertier",
            // icon: "cil-layers",
            key: "member_tier",
          },

          {
            id: 28,
            _name: "CSidebarNavItem",
            name: "Staff",
            to: "/manageStaff",
            // icon: "cil-people",
            key: "manageStaff",
          },
          {
            id: 29,
            _name: "CSidebarNavItem",
            name: "Branch",
            to: "/manageBranch",
            // icon: "cil-home",
            key: "manageBranch",
          },
          {
            id: 30,
            _name: "CSidebarNavItem",
            name: "Admin",
            to: "/managePermission",
            key: "permission Admin",
          },
          {
            id: 31,
            _name: "CSidebarNavItem",
            name: "Role",
            to: "/manageRole",
            key: "permission Role",
          },
          {
            id: 32,
            _name: "CSidebarNavItem",
            name: "Setting",
            to: "/setting",
            key: "setting",
          },
        ],
      },
    ],
  },
];
