<template>
  <div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar">
            <img src="/img/avatars/9.png" class="c-avatar-img" />
          </div>
        </CHeaderNavLink>
      </template>
      <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" />Logout
      </CDropdownItem>
    </CDropdown>
    
    <ModalConfirm
      id="logout"
      ref="confirmModal"
      text="You want to log out"
      @handler="deleteMsg"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    ModalConfirm,
  },
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.getName();
  },
  methods: {
    getName() {
      this.name = this.$cookies.get("back_office_admin_userName");
    },
    async deleteMsg(value) {
      await this.$store.dispatch("getLogout");
      const data = this.$store.state.setting.stateLogout;
      if (data.result === 1) {
        this.getLogout(value);
      }
    },
    getLogout(value) {
      if (value === true) {
        this.$cookies.remove("back_office_admin_token");
        this.$cookies.remove("permission_menu_admin");
        localStorage.removeItem("back_office_admin_permission");
        this.$cookies.remove("back_office_admin_user_guid");
        this.$cookies.remove("back_office_admin_email");
        this.$cookies.remove("back_office_admin_userName");
        window.location.href = "/";
      }
    },
    logout() {
      this.$refs.confirmModal.show();
    },
    // logout: async function () {
    //   this.$swal({
    //     title: "You want to log out?",
    //     icon: "info",
    //     buttons: ["Cancel", "Yes"],
    //     dangerMode: true,
    //   }).then((logout) => {
    //     if (logout) {
    //       this.$cookies.remove("back_office_admin_token");
    //       this.$cookies.remove("permission_menu_admin");
    //       window.location.href = "/";
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
